import axios from 'axios';

import config from 'config';

const { zendeskUrl } = config;

const zendeskApi = axios.create({
  baseURL: zendeskUrl,
  timeout: 5000,
});

export default zendeskApi;
