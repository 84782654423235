import { ZendeskSettings, zEType } from 'context/contextZendesk/generalType';

export const updateZendeskScript = (jwtToken: string): void => {
  window.zESettings = {
    webWidget: {
      authenticate: {
        chat: {
          jwtFn: (callback) => callback(jwtToken),
        },
      },
      answerBot: {
        suppress: false,
        title: {
          '*': 'Live Chat',
        },
        contactOnlyAfterQuery: true,
        search: {
          labels: false,
        },
        avatar: {
          url: 'https://theme.zdassets.com/theme_assets/15266025/c08b19ba5061923217980aff47cf9e68173f2d15.png',
          name: {
            '*': 'StarBets',
          },
        },
      },
    },
  };
};

declare global {
  interface Window {
    zESettings?: ZendeskSettings;
    zE?: zEType;
  }
}
