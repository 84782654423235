import dayjs from 'dayjs';
import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ZendeskContext from 'context/contextZendesk/context';
import { FAQSort } from 'components/constants/faq';
import { IZendeskArticle } from 'types';
import { DataLayerEvent } from 'types/dataLayerTypes';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import FAQArticle from '../FAQArticle';

import styles from './styles.module.scss';
import { IFAQArticles } from './types';

const FAQArticles: React.FC<IFAQArticles> = ({ sectionId, search, sort }) => {
  const { state } = useLocation();
  const { articles: persistedArticles } = useContext(ZendeskContext);
  const dataLayerDispatch = useAnalyticsDispatch();
  const user = useAppSelector(userProfile);

  const [allArticles, setAllArticles] = useState<IZendeskArticle[]>([]);
  const [articles, setArticles] = useState<IZendeskArticle[]>([]);
  const [open, setOpen] = useState<number[]>([]);

  useEffect(() => {
    const articleOnOpen = pathOr<number | undefined>(undefined, ['articleId'], state);

    if (articleOnOpen) {
      setOpen((o) => [...o, articleOnOpen]);
    }
  }, [state]);

  useEffect(() => {
    const newArticles = persistedArticles.filter((a) => a.section_id === sectionId);

    if (sort === FAQSort.date) {
      newArticles.sort((a, b) => (dayjs(a.created_at).isBefore(b.created_at) ? 1 : -1));
    } else {
      newArticles.sort((a) => (a.promoted ? 1 : -1));
    }

    setAllArticles(newArticles);
    setArticles(newArticles);
  }, [sectionId, sort, persistedArticles]);

  useEffect(() => {
    if (search) {
      const searchValue = search.trim().toLowerCase();

      const newSectionArticles = allArticles.filter((a) => {
        const title = a.title.trim().toLowerCase();

        return title.includes(searchValue);
      });

      setArticles(newSectionArticles);
    } else {
      setArticles(allArticles);
    }
  }, [search, allArticles]);

  const handleOpen = (articleId: number, title: string) => {
    if (open.includes(articleId)) {
      setOpen((o) => o.filter((id) => id !== articleId));
      return;
    }

    setOpen((o) => [...o, articleId]);
    dataLayerDispatch({ event: DataLayerEvent.faq, user_id: user?.id, question: title });
  };

  return (
    <ul className={styles.wrapper}>
      {articles.map((a) => (
        <FAQArticle key={a.id} article={a} open={open.includes(a.id)} onOpen={() => handleOpen(a.id, a.title)} />
      ))}
    </ul>
  );
};

export default FAQArticles;
