import clsx from 'clsx';
import QRCode from 'qrcode';
import { pathOr } from 'ramda';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import copyIcon from 'assets/img/common/copyIcon.svg';
import warningIcon from 'assets/img/common/warning-icon.webp';

import { Links } from 'components/constants';
import { ContextPopUps, ContextTokenCode } from 'context';
import { GET_DEPOSIT_ADDRESS } from 'graphQl/query/user/getDepositAddress';
import {
  filterNetworksAddressBook,
  filterNetworksDeposit,
  getCurrencyDropDeposit,
  getMinimumDeposit,
} from 'func/prepareDataPopUps';
import { useLocalization } from 'components/Internationalization';
import { INetworkAddress } from 'types';

import DropDown from 'components/Base/DropDown';
import DepositBonus from './components/DepositBonus';
import Link from 'components/Base/Links/Link';

import { IDeposit } from './types';
import styles from './styles.module.scss';

const Deposit: React.FC<IDeposit> = ({ selectedToken, selectedNetwork, setSelectedNetwork, setSelectedToken }) => {
  const { translate } = useLocalization();
  const { tokenCode, tokenWithNetworksList } = useContext(ContextTokenCode);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const ref = useRef();

  const [tokenData, setTokenData] = useState(null);
  const [dropDownInfo, setDropDownInfo] = useState(null);
  const [networkDropdown, setNetworkDropdown] = useState(null);
  const [addressTooltip, setAddressTooltip] = useState(false);
  const [memoTooltip, setMemoTooltip] = useState(false);
  const [depositAddress, setDepositAddress] = useState('');
  const [depositMemo, setDepositMemo] = useState('');
  const [minimumDeposit, setMinimumDeposit] = useState([]);

  const [depositAddressRequest] = useLazyQuery(GET_DEPOSIT_ADDRESS, { fetchPolicy: 'cache-and-network' });

  const handleTooltip = (field: 'address' | 'memo') => {
    const handler = field === 'address' ? setAddressTooltip : setMemoTooltip;

    handler(true);
    setTimeout(() => handler(false), 1000);
  };

  const handleRequestWalletAddress = async () => {
    const res = tokenWithNetworksList.tokenWithNetworks.find((elem) => elem.tokenCode === selectedToken.token);
    setMinimumDeposit(res.networks);

    await depositAddressRequest({ variables: { network: selectedNetwork } })
      .then((data) => {
        const newDepositaddress = pathOr<INetworkAddress>(null, ['data', 'depositAddress'], data);

        if (newDepositaddress) {
          const { address: newAddress, memo: newMemo } = newDepositaddress;

          setDepositAddress(newAddress);
          setDepositMemo(newMemo || '');
          QRCode.toCanvas(ref.current, newAddress);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (tokenWithNetworksList) {
      setTokenData(tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.networks.length >= 1));
    }
  }, [tokenWithNetworksList]);

  useEffect(() => {
    if (tokenData) {
      const prepareData = getCurrencyDropDeposit(
        tokenData,
        tokenWithNetworksList.networks,
        setNetworkDropdown,
        setSelectedNetwork,
        setSelectedToken,
      );
      const indexToken = prepareData.findIndex((elem) => elem.tokenCode === selectedToken.token);
      const firstElem = prepareData.splice(indexToken, 1);
      setDropDownInfo([firstElem[0], ...prepareData]);
      setSelectedToken({ token: firstElem[0]?.tokenCode, name: firstElem[0]?.title });
      if (firstElem[0]?.networks) {
        filterNetworksDeposit(
          firstElem[0]?.networks,
          tokenWithNetworksList.networks,
          setNetworkDropdown,
          setSelectedNetwork,
        );
        if (selectedNetwork) {
          const res = filterNetworksAddressBook(
            firstElem[0]?.networks,
            tokenWithNetworksList.networks,
            selectedNetwork,
            setSelectedNetwork,
          );
          setNetworkDropdown(res);
          setSelectedNetwork(selectedNetwork);
        }
      } else {
        setNetworkDropdown([{ title: '' }]);
        setDropDownInfo([{ title: '' }]);
      }
    }
  }, [tokenData, tokenCode]);

  useEffect(() => {
    if (selectedToken) {
      setDepositAddress('');
    }

    if (selectedNetwork && selectedToken) {
      handleRequestWalletAddress();
    }
  }, [selectedNetwork, selectedToken]);

  if (!dropDownInfo || !networkDropdown) return null;

  return (
    <>
      <h2 className={styles.depositTitle}>{translate('popups.wallet.deposit')}</h2>
      <DepositBonus />
      <div className={styles.depositCurrencyWrap}>
        <div className={styles.depositCurrencyItem}>
          <p className={styles.depositCurrencyTitle}>{translate('popups.wallet.deposit.currency')}:</p>
          <DropDown data={dropDownInfo} width="100%" textPadding="50px" hideFirstElem="0" />
        </div>
        <div className={styles.depositCurrencyItem}>
          <p className={styles.depositCurrencyTitle}>{translate('popups.wallet.deposit.network')}:</p>
          <DropDown data={networkDropdown} width="100%" textPadding="20px" />
        </div>
      </div>
      {selectedNetwork && (
        <div className={styles.depositContentWrap}>
          <div className={styles.depositContentCopyWrap}>
            <p className={styles.depositCurrencyTitle}>{`${
              selectedToken ? selectedToken.name : tokenCode.name
            } ${translate('popups.wallet.deposit.address')}:`}</p>
            <CopyToClipboard text={depositAddress}>
              <div className={styles.depositAddressWrap}>
                <p>{depositAddress}</p>
                <div className={clsx(styles.depositTooltip, addressTooltip ? styles.depositTooltipOpen : '')}>
                  {translate('general.buttons.copy')}
                </div>
                <img
                  className={styles.depositTooltipIcon}
                  src={copyIcon}
                  alt="icon"
                  onClick={() => handleTooltip('address')}
                />
              </div>
            </CopyToClipboard>
          </div>
          {depositMemo ? (
            <div className={styles.depositContentCopyWrap}>
              <p className={styles.depositCurrencyTitle}>{`${
                selectedToken ? selectedToken.name : tokenCode.name
              } ${translate('popups.wallet.deposit.memo')}:`}</p>
              <CopyToClipboard text={depositMemo}>
                <div className={styles.depositAddressWrap}>
                  <p>{depositMemo}</p>
                  <div className={clsx(styles.depositTooltip, memoTooltip ? styles.depositTooltipOpen : '')}>
                    {translate('general.buttons.copy')}
                  </div>
                  <img
                    className={styles.depositTooltipIcon}
                    src={copyIcon}
                    alt="icon"
                    onClick={() => handleTooltip('memo')}
                  />
                </div>
              </CopyToClipboard>
            </div>
          ) : null}
          <div className={styles.depositContentMinimumAmount}>
            {translate('popups.wallet.deposit.min-deposit')}:<p>{getMinimumDeposit(minimumDeposit, selectedNetwork)}</p>
            {selectedToken.name}
          </div>
          <div className={styles.depositQr}>
            <div className={styles.depositQrBorder}>
              <canvas ref={ref} className={styles.depositQrCode} />
            </div>
            <div className={styles.depositQrContent}>
              <h5>
                <img src={warningIcon} alt="icon" />
                {translate('popups.wallet.deposit.warning.title')}
              </h5>
              <h6>
                {translate(
                  depositMemo ? 'popups.wallet.deposit.warning.memo-desc' : 'popups.wallet.deposit.warning.desc',
                )}
              </h6>
            </div>
          </div>
          <Link onClick={() => setPopUpsOpen(null)} className={styles.depositLink} to={Links.transactions}>
            {translate('popups.wallet.deposit.transactions')}
          </Link>
        </div>
      )}
    </>
  );
};

export default Deposit;
