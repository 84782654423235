import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ZendeskContext from 'context/contextZendesk/context';
import { Links } from 'components/constants';
import { IZendeskSection } from 'types';
import { IPageIcon, getFAQSectionIcon } from 'func/faq';
import { DEFAULT_META_DESCRIPTION } from 'components/constants/constants';
import { FAQSort } from 'components/constants/faq';
import { useNavigate } from 'hooks/useNavigate';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitle from 'components/Base/PageTitle';
import StyledLink from 'components/Base/Links/StyledLink';
import FAQToolbar from '../FAQPage/components/FAQToolbar';
import FAQArticles from './components/FAQArticles';

import styles from './styles.module.scss';

const FAQCategoryPage: React.FC = () => {
  const navigate = useNavigate();
  const { categoryName } = useParams<{ categoryName?: string }>();
  const { sections } = useContext(ZendeskContext);

  const [category, setCategory] = useState<IZendeskSection>();
  const [icon, setIcon] = useState<IPageIcon>();
  const [sortBy, setSortBy] = useState(FAQSort.date);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (categoryName) {
      const newCategory = sections.find((s) => s.name?.toLowerCase() === categoryName);
      const newIcon = getFAQSectionIcon(newCategory?.name);

      setCategory(newCategory);
      setIcon(newIcon);
    } else {
      navigate(Links.faq);
    }
  }, [categoryName, sections]);

  const handleChangeSort = (sort: string) => {
    setSortBy(sort as FAQSort);
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  return category ? (
    <main className={styles.wrapper}>
      <DocumentTitle data={{ title: `${category.name} FAQ`, description: DEFAULT_META_DESCRIPTION }} />
      <PageTitle icon={icon.icon} title={`${category.name} FAQ`} lineColor={icon.color} titleSize="big" largeImage>
        <StyledLink to={Links.faq} className={styles.link}>
          Go Back
        </StyledLink>
      </PageTitle>
      <FAQToolbar sort={sortBy} search={search} onChangeSort={handleChangeSort} onChangeSearch={handleChangeSearch} />
      <FAQArticles sort={sortBy} search={search} sectionId={category.id} />
    </main>
  ) : null;
};

export default FAQCategoryPage;
