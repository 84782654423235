import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IZendeskArticle } from 'types';
import { useLocalization } from 'components/Internationalization';
import { Language } from 'components/Internationalization/types';
import ZendeskContext from 'context/contextZendesk/context';

import Title from './components/Title';
import FAQContent from './components/FAQContent';
import FAQSectionStyle from './styles';

const FAQSection: React.FC = () => {
  const { pathname } = useLocation();
  const { language } = useLocalization();
  const { sections, articles } = useContext(ZendeskContext);

  const [category, setCategory] = useState('');
  const [sectionId, setSectionId] = useState<number>();
  const [sectionArticles, setSectionArticles] = useState<IZendeskArticle[]>([]);

  useEffect(() => {
    const splittedLocation = pathname.split('/');
    const index = language === Language.english ? 1 : 2;
    const newCategory = splittedLocation[index];

    setCategory(newCategory);
  }, [pathname, language]);

  useEffect(() => {
    if (category && sections.length) {
      const section = sections.find((s) => s.name.toLowerCase() === category.toLowerCase());

      setSectionId(section?.id);
    }
  }, [category, sections]);

  useEffect(() => {
    if (sectionId && articles.length) {
      const filteredArticles = articles.filter((a) => a.section_id === sectionId);

      setSectionArticles(filteredArticles);
    }
  }, [articles, sectionId]);

  if (!sectionArticles.length || !sectionId) return null;

  return (
    <FAQSectionStyle>
      <Title />
      <FAQContent sectionArticles={sectionArticles} />
    </FAQSectionStyle>
  );
};

export default FAQSection;
