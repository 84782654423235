import { useContext, useState } from 'react';

import faqIcon from 'assets/img/PageTitles/FAQ_Icon.svg';

import ZendeskContext from 'context/contextZendesk/context';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';
import { FAQSort } from 'components/constants/faq';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitle from 'components/Base/PageTitle';

import FAQToolbar from './components/FAQToolbar';
import FAQCategories from './components/FAQCategories';

import styles from './styles.module.scss';

const FAQPage: React.FC = () => {
  const { sections, loading } = useContext(ZendeskContext);

  const [sortBy, setSortBy] = useState(FAQSort.date);
  const [search, setSearch] = useState('');

  const handleChangeSort = (sort: string) => {
    setSortBy(sort as FAQSort);
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <main className={styles.wrapper}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.faq} />
      <PageTitle icon={faqIcon} title="faq" lineColor="#C22328" titleSize="big" largeImage />
      <FAQToolbar sort={sortBy} search={search} onChangeSort={handleChangeSort} onChangeSearch={handleChangeSearch} />
      <FAQCategories sort={sortBy} search={search} categories={sections} loading={loading} />
    </main>
  );
};

export default FAQPage;
