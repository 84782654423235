import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import ZendeskContext from 'context/contextZendesk/context';
import { FAQSort } from 'components/constants/faq';
import { IZendeskArticle } from 'types';
import { getFAQLink, getFAQSectionIcon, getTopicTitle, highlightText } from 'func/faq';
import { useNavigate } from 'hooks/useNavigate';

import StyledLink from 'components/Base/Links/StyledLink';
import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IFAQCategory } from './types';

const FAQCategory: React.FC<IFAQCategory> = ({ sort, search, category }) => {
  const navigate = useNavigate();
  const { articles } = useContext(ZendeskContext);

  const [link, setLink] = useState('');
  const [allSectionArticles, setAllSectionArticles] = useState<IZendeskArticle[]>([]);
  const [sectionArticles, setSectionArticles] = useState<IZendeskArticle[]>([]);

  useEffect(() => {
    const newLink = getFAQLink(category.name);

    setLink(newLink);
  }, []);

  useEffect(() => {
    const sectionId = category.id;

    const newSectionArticles = articles.filter((a) => a.section_id === sectionId);

    if (sort === FAQSort.date) {
      newSectionArticles.sort((a, b) => (dayjs(a.created_at).isBefore(b.created_at) ? 1 : -1));
    } else {
      newSectionArticles.sort((a) => (a.promoted ? 1 : -1));
    }

    setSectionArticles(newSectionArticles);
    setAllSectionArticles(newSectionArticles);
  }, [articles, sort]);

  useEffect(() => {
    if (search) {
      const searchValue = search.trim().toLowerCase();

      const newSectionArticles = allSectionArticles.filter((a) => {
        const title = a.title.trim().toLowerCase();

        return title.includes(searchValue);
      });

      setSectionArticles(newSectionArticles);
    } else {
      setSectionArticles(allSectionArticles);
    }
  }, [search, allSectionArticles]);

  const handleLinkClick = () => {
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  const handleOpenArticle = (articleId: number) => {
    navigate(link, { state: { articleId } });
    handleLinkClick();
  };

  return sectionArticles.length ? (
    <article className={styles.wrapper}>
      <Link className={styles.titleWrapper} to={link} onClick={handleLinkClick}>
        <img src={getFAQSectionIcon(category.name).icon} alt={category.name} />
        <p>{category.name}</p>
      </Link>
      <ul className={styles.articles}>
        {sectionArticles.map((a) => {
          const title = search ? highlightText(a.title, search, styles.highlight) : a.title;

          return (
            <li
              key={a.id}
              className={styles.article}
              dangerouslySetInnerHTML={{ __html: title }}
              onClick={() => handleOpenArticle(a.id)}
            />
          );
        })}
        <li className={styles.articleHidden} />
      </ul>
      <div className={styles.info}>
        <p className={styles.topics}>
          {sectionArticles.length} {getTopicTitle(sectionArticles.length)}
        </p>
        <StyledLink to={link} onClick={handleLinkClick}>
          View More
        </StyledLink>
      </div>
    </article>
  ) : null;
};

export default FAQCategory;
