import { gql } from '@apollo/client';

export const GET_DEPOSIT_ADDRESS = gql`
  query depositAddress($network: NetworkCode!) {
    depositAddress(network: $network) {
      address
      memo
    }
  }
`;
