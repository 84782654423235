import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useLazyQuery } from '@apollo/client';

import { IZendeskArticle, IZendeskSection } from 'types';
import { AUTH_ZENDESK } from 'graphQl/query/zendesk/zendesk';
import { updateZendeskScript } from 'func/zendesk';

import zendeskApi from './api';
import ZendeskContext from './context';

import { IZendeskArticlesResponse, IZendeskSectionsResponse } from './types';

const ZendeskProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [sections, setSections] = useState<IZendeskSection[]>([]);
  const [sectionsLoading, setSectionsLoading] = useState(false);
  const [articles, setArticles] = useState<IZendeskArticle[]>([]);
  const [articlesLoading, setArticlesLoading] = useState(false);

  const [zendeskJwt] = useLazyQuery(AUTH_ZENDESK);

  useEffect(() => {
    getSections();
    getArticles();
  }, []);

  async function getSections(): Promise<IZendeskSection[]> {
    setSectionsLoading(true);

    return zendeskApi
      .get<IZendeskSectionsResponse>('/sections.json')
      .then((res) => {
        const newSections = pathOr<IZendeskSection[]>([], ['data', 'sections'], res);

        setSections(newSections);

        return newSections;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[ZENDESK_SECTIONS_ERROR]:', err);

        return [];
      })
      .finally(() => {
        setSectionsLoading(false);
      });
  }

  async function getArticles(url?: string) {
    setArticlesLoading(true);

    const requestUrl = url || '/articles.json?per_page=100';

    await zendeskApi
      .get<IZendeskArticlesResponse>(requestUrl)
      .then((res) => {
        const nextPage = pathOr<string | false>(false, ['data', 'next_page'], res);
        const newArticles = pathOr<IZendeskArticle[]>([], ['data', 'articles'], res);

        setArticles((a) => [...a, ...newArticles]);

        if (nextPage) {
          getArticles(nextPage);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[ZENDESK_ARTICLES_ERROR]:', err);
      })
      .finally(() => {
        setArticlesLoading(false);
      });
  }

  const authUser = async () => {
    const tokenResponse = await zendeskJwt()
      .then((res) => res.data)
      .catch(() => null);
    const zendeskJwtToken = pathOr(null, ['zendeskJwt'], tokenResponse);

    if (zendeskJwtToken) {
      updateZendeskScript(zendeskJwtToken);
    }
  };

  const hideZendesk = () => window.zE('webWidget', 'hide');

  return (
    <ZendeskContext.Provider
      value={{
        sections,
        articles,
        loading: sectionsLoading || articlesLoading,
        authUser,
        hideZendesk,
      }}
    >
      {children}
    </ZendeskContext.Provider>
  );
};

export default ZendeskProvider;
