import React from 'react';

import { IZendeskContext } from './types';

const ZendeskContext = React.createContext<IZendeskContext>({
  sections: [],
  articles: [],
  loading: false,
  authUser: () => null,
  hideZendesk: () => null,
});

export default ZendeskContext;
